import service from '@/utils/request'

// @Tags GroundBoxMonitorConfig
// @Summary 创建GroundBoxMonitorConfig
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.GroundBoxMonitorConfig true "创建GroundBoxMonitorConfig"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /devGroundBoxMonitorConfig/createGroundBoxMonitorConfig [post]
export const createGroundBoxMonitorConfig = (data) => {
  return service({
    url: '/devGroundBoxMonitorConfig/createGroundBoxMonitorConfig',
    method: 'post',
    data
  })
}

// @Tags GroundBoxMonitorConfig
// @Summary 删除GroundBoxMonitorConfig
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.GroundBoxMonitorConfig true "删除GroundBoxMonitorConfig"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /devGroundBoxMonitorConfig/deleteGroundBoxMonitorConfig [delete]
export const deleteGroundBoxMonitorConfig = (data) => {
  return service({
    url: '/devGroundBoxMonitorConfig/deleteGroundBoxMonitorConfig',
    method: 'delete',
    data
  })
}

// @Tags GroundBoxMonitorConfig
// @Summary 删除GroundBoxMonitorConfig
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除GroundBoxMonitorConfig"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /devGroundBoxMonitorConfig/deleteGroundBoxMonitorConfig [delete]
export const deleteGroundBoxMonitorConfigByIds = (data) => {
  return service({
    url: '/devGroundBoxMonitorConfig/deleteGroundBoxMonitorConfigByIds',
    method: 'delete',
    data
  })
}

// @Tags GroundBoxMonitorConfig
// @Summary 更新GroundBoxMonitorConfig
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.GroundBoxMonitorConfig true "更新GroundBoxMonitorConfig"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /devGroundBoxMonitorConfig/updateGroundBoxMonitorConfig [put]
export const updateGroundBoxMonitorConfig = (data) => {
  return service({
    url: '/devGroundBoxMonitorConfig/updateGroundBoxMonitorConfig',
    method: 'put',
    data
  })
}

// @Tags GroundBoxMonitorConfig
// @Summary 用id查询GroundBoxMonitorConfig
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data query model.GroundBoxMonitorConfig true "用id查询GroundBoxMonitorConfig"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /devGroundBoxMonitorConfig/findGroundBoxMonitorConfig [get]
export const findGroundBoxMonitorConfig = (params) => {
  return service({
    url: '/devGroundBoxMonitorConfig/findGroundBoxMonitorConfig',
    method: 'get',
    params
  })
}

// @Tags GroundBoxMonitorConfig
// @Summary 分页获取GroundBoxMonitorConfig列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data query request.PageInfo true "分页获取GroundBoxMonitorConfig列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /devGroundBoxMonitorConfig/getGroundBoxMonitorConfigList [get]
export const getGroundBoxMonitorConfigList = (params) => {
  return service({
    url: '/devGroundBoxMonitorConfig/getGroundBoxMonitorConfigList',
    method: 'get',
    params
  })
}
